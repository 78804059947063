import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

moment.locale(window.locale, {
  months: [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
  ],
  monthsShort: [
    'gen',
    'feb',
    'mar',
    'apr',
    'mag',
    'giu',
    'lug',
    'ago',
    'set',
    'ott',
    'nov',
    'dic',
  ],
  weekdays: [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
  ],
  weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  weekdaysMin: ['D', 'L', 'Ma', 'Me', 'G', 'V', 'S'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'D MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'G/M/AAAA',
    LT: null, // 'H:mm' or 'O:mm'? TODO https://trello.atlassian.net/browse/PANO-2026
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[Oggi alle] LT',
    nextDay: '[Domani alle] LT',
    nextWeek: 'dddd [alle] LT',
    lastDay: '[Ieri alle] LT',
    lastWeek() {
      switch (this.day()) {
        case 0:
          return '[la scorsa] dddd [alle] LT';
        default:
          return '[lo scorso] dddd [alle] LT';
      }
    },
    sameYear: 'llll [alle] LT',
    sameElse: 'll [alle] LT',
  }),
  relativeTime: {
    future(s) {
      return `${/^[0-9].+$/.test(s) ? 'tra' : 'in'} ${s}`;
    },
    past: '%s fa',
    s: 'alcuni secondi',
    m: 'un minuto',
    mm: '%d minuti',
    h: "un'ora",
    hh: '%d ore',
    d: 'un giorno',
    dd: '%d giorni',
    M: 'un mese',
    MM: '%d mesi',
    y: 'un anno',
    yy: '%d anni',
  },
  ordinalParse: /\d{1,2}º/,
  ordinal: () => '%dº',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
